import { OSSUpload } from '@/utils/apiBase.js';
import MDRouter from "@/service/router";
import domain from '@/service/domain';
import { mdToast, mdAlert } from "@/utils/tools";
import { goToLogin } from "@/utils/user";
import ImageCropper from '@@/idea/imageCropper';
import { getIdeaCategoryList, getQueryinfo, createIdea } from "@/api";
export default {
  components: {
    ImageCropper
  },
  data() {
    return {
      loading: false,
      stepIndex: 0,
      show: false,
      categoryList: [],
      fileList: [],
      category: '',
      categoryName: '请选择',
      proName: '',
      checked: "1",
      cropperVisible: false,
      imgUrl: '',
      fileInfo: {},
      auth_status: 1,
      auth_txt: '当前摩点账号未认证，为加快项目审核请先提交账号认证。',
      auth_btn_txt: '点击前往',
      imgContent: [],
      txtShow: false,
      is_title: 0,
      txtDialogMessage: '',
      editIndex: -1,
      createIdeaVisible: false,
      xieyiAgree: false,
      describeDialogMessage: '',
      describeShow: false,
      progress: 0
    };
  },
  computed: {
    txtBlockLength() {
      let result = 0;
      this.imgContent.forEach(ele => {
        if (ele.content_type == 1) {
          result++;
        }
      });
      return result;
    },
    picBlockLength() {
      let result = 0;
      this.imgContent.forEach(ele => {
        if (ele.content_type == 2) {
          result++;
        }
      });
      return result;
    },
    mediaBlockLength() {
      let result = 0;
      this.imgContent.forEach(ele => {
        if (ele.content_type == 3) {
          result++;
        }
      });
      return result;
    },
    txtAllLength() {
      let result = 0;
      this.imgContent.forEach(ele => {
        if (ele.content_type == 1) {
          result += ele.content_value.length;
        }
      });
      return result;
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.titleText("发布创意");
    document.title = '发布创意';
    this.get_category_list();
  },
  methods: {
    focusItem(item, index) {
      this.editIndex = index;
    },
    editTxt(item, index) {
      this.editIndex = index;
      this.txtDialogMessage = item.content_value;
      this.is_title = item.is_title;
      this.txtShow = true;
    },
    editDescribe(item, index) {
      this.editIndex = index;
      this.describeDialogMessage = item.describe;
      this.describeShow = true;
    },
    describeConfirm() {
      this.imgContent[this.editIndex].describe = this.describeDialogMessage;
      this.describeShow = false;
    },
    txtConfirm() {
      if (this.editIndex == -1) {
        this.imgContent.push({
          "content_type": 1,
          "content_value": this.txtDialogMessage,
          "is_title": this.is_title
        });
      } else {
        this.imgContent[this.editIndex].is_title = this.is_title;
        this.imgContent[this.editIndex].content_value = this.txtDialogMessage;
      }
      this.txtShow = false;
      this.txtDialogMessage = '';
      this.is_title = 0;
    },
    delItem(index) {
      this.imgContent.splice(index, 1);
      this.editIndex = -1;
    },
    appendMixContent(index) {
      switch (index) {
        case 1:
          if (this.txtBlockLength >= 20) {
            mdToast('最多插入20段文字');
            return false;
          }
          this.editIndex = -1;
          this.txtShow = true;
          break;
      }
    },
    changeTitle() {
      if (this.is_title == 1) {
        this.is_title = 0;
      } else {
        this.is_title = 1;
      }
    },
    checkCategory(item) {
      this.category = item.catename_en;
      this.categoryName = item.catename_ch;
      this.show = false;
    },
    onOversize() {
      mdToast('文件大小不能超过5MB');
    },
    del(index) {
      this.fileList.splice(index, 1);
    },
    cropperSuccess(item) {
      this.fileList.push(item);
      this.cropperVisible = false;
    },
    async uploadImg(e) {
      this.hasChange = true;
      let that = this;
      let file = e.target.files[0];
      let file_size = file.size / 1024 / 1024;
      let re = /(?:\.([^.]+))?$/;
      let new_ext = re.exec(file.name)[1];
      let ext = new_ext.toLocaleLowerCase();
      if (file_size > 5) {
        mdToast('图片' + file.name + '大小不能超过5M');
        e.target.value = '';
        return;
      } else {
        if (!(ext == "jpg" || ext == "png" || ext == "jpeg")) {
          mdToast('图片' + file.name + '格式仅支持jpg,jpeg,png格式');
          e.target.value = '';
          return;
        }
      }
      let res = await that.getBase64(file, 1080).catch(err => {
        mdToast(err);
        e.target.value = '';
        return false;
      });
      if (res) {
        this.imgUrl = res;
        this.cropperVisible = true;
        e.target.value = '';
      } else if (res == 1) {
        this.loading = true;
        let filesArr = [];
        filesArr.push(file);
        let option = {
          status: 0,
          success: item => {
            console.log(item);
            this.loading = false;
            this.fileList.push(item);
          },
          error: (e, msg) => {
            mdToast({
              type: "text",
              message: msg,
              onClose: () => {}
            });
            this.loading = false;
          }
        };
        OSSUpload(option, filesArr, 0);
      }
    },
    async uploadContentImg(e) {
      let that = this;
      let file = e.target.files[0];
      let file_size = file.size / 1024 / 1024;
      let re = /(?:\.([^.]+))?$/;
      let new_ext = re.exec(file.name)[1];
      let ext = new_ext.toLocaleLowerCase();
      if (file_size > 5) {
        mdToast('图片' + file.name + '大小不能超过1M');
        e.target.value = '';
        return;
      } else {
        if (!(ext == "jpg" || ext == "png" || ext == "jpeg")) {
          mdToast('图片' + file.name + '格式仅支持jpg,jpeg,png格式');
          e.target.value = '';
          return;
        }
      }
      let res = await that.getBase64(file, 750).catch(err => {
        mdToast(err);
        e.target.value = '';
        return false;
      });
      if (res == 1) {
        this.loading = true;
        let filesArr = [];
        filesArr.push(file);
        let option = {
          status: 0,
          success: item => {
            console.log(item);
            this.getImageDimensions(item, (width, height) => {
              this.imgContent.push({
                "content_type": 2,
                "content_value": item,
                "describe": '',
                "width": width.toString(),
                "height": height.toString()
              });
            });
            this.loading = false;
          },
          error: (e, msg) => {
            mdToast({
              type: "text",
              message: msg,
              onClose: () => {}
            });
            this.loading = false;
          }
        };
        OSSUpload(option, filesArr, 0);
      }
    },
    uploadVideoChange(event) {
      let up_event = event;
      let file = event.target.files[0];
      let tailArr = file.name.indexOf('.') != -1 ? file.name.split('.') : [];
      if (tailArr.length == 0 || tailArr[tailArr.length - 1].toLowerCase() != 'mp4' || !file.type.match(/mp4/ig)) {
        mdToast('上传视频格式不正确，请上传mp4格式视频');
        event.target.value = '';
        return false;
      }
      if (file.size > 500 * Math.pow(1024, 2)) {
        mdToast('上传视频大小不超过500M');
        event.target.value = '';
        return false;
      }
      this.loading = true;
      let option = {
        status: 1,
        success: (item, cover, i, flag, list, file_info) => {
          this.imgContent.push({
            "content_type": 3,
            "content_value": item,
            "video_cover": cover,
            "cover_width": file_info.width.toString(),
            "cover_height": file_info.height.toString()
          });
          up_event.target.value = '';
          this.loading = false;
          this.progress = 0;
        },
        error: msg => {
          mdToast(msg);
          this.loading = false;
          this.progress = 0;
        }
      };
      let filesArr = [];
      filesArr.push(file);
      OSSUpload(option, filesArr, p => {
        console.log(p);
        this.progress = p;
      });
    },
    getBase64(file, width) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = () => {
          imgResult = reader.result;
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.onloadend = () => {
          const image = new Image();
          image.onload = () => {
            if (event.target.width < width) {
              reject('建议图片宽度大于' + width + 'px');
            } else if (event.target.width / event.target.height == 4 / 5 || width == 750) {
              resolve(1);
            } else {
              resolve(imgResult);
            }
          };
          image.src = imgResult;
        };
      });
    },
    async comfirm() {
      const {
        status,
        data,
        message
      } = await getQueryinfo({
        type: 'ideas'
      });
      if (status == 0) {
        if (!data.can_release) {
          mdAlert({
            title: '认证信息',
            content: data.release_msg,
            actions: [data.need_auth ? '去认证' : '好的'],
            _callback: () => {
              if (data.need_auth) {
                const url = domain.wap + "/identity/verify";
                MDRouter.Link(url);
              }
            }
          });
          return false;
        }
      } else if (status == 10000) {
        goToLogin();
      } else {
        mdToast(message);
      }
      if (!this.category) {
        mdToast('请选择项目类别');
        return false;
      }
      if (!this.proName) {
        mdToast('请填写创意标题');
        return false;
      }
      if (this.isFormat(this.proName)) {
        mdToast('项目标题中禁止使用相关英文符号');
        return false;
      }
      if (this.fileList.length == 0) {
        mdToast('请上传轮播图');
        return false;
      }
      this.stepIndex = 1;
    },
    // 发布
    publish() {
      if (this.imgContent.length == 0) {
        mdToast('更新内容，图片或视频至少填写一种');
        return false;
      }
      this.createIdeaVisible = true;
    },
    confirm_publish() {
      if (!this.xieyiAgree) {
        mdToast('请先同意协议');
        return false;
      }
      this.loading = true;
      this.createIdeaVisible = false;
      let logo1 = JSON.stringify(this.fileList);
      let ideaObj = {
        title: this.proName,
        category: this.category,
        logos: logo1,
        is_now_release: Number(this.checked)
      };
      let updateObj = {
        mix_content: this.imgContent
      };
      let option = {
        idea_info: JSON.stringify(ideaObj),
        update_info: JSON.stringify(updateObj)
      };
      createIdea(option).then(res => {
        this.loading = false;
        if (res.status == 0) {
          this.createIdeaVisible = false;
          MDRouter.goIdeaInfo(res.data.idea_id);
        } else {
          mdToast(res.message);
        }
      });
    },
    isFormat(str) {
      return /([&"'])/g.test(str);
    },
    get_category_list() {
      getIdeaCategoryList({}).then(async res => {
        if (res.status == 0) {
          this.categoryList = [];
          this.categoryList.push(...res.data);
        }
      });
    },
    getImageDimensions(url, callback) {
      var img = new Image();
      img.onload = function () {
        callback(img.naturalWidth, img.naturalHeight);
      };
      img.src = url;
    },
    goInitXieyi() {
      let newTab = window.open('about:blank');
      newTab.location.href = 'https://zhongchou.modian.com/about/index/3/2.html';
    }
  }
};