import { OSSUpload } from '@/utils/apiBase.js';
import { mdToast } from "@/utils/tools";
import { VueCropper } from 'vue-cropper';
import 'vue-cropper/dist/index.css';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    //控制是否显示
    url: String,
    //裁剪的图片
    fileInfo: Object
  },
  components: {
    VueCropper
  },
  computed: {
    show() {
      return this.visible;
    }
  },
  data() {
    return {
      loading: false,
      option: {
        // outputSize: 0.8,
        info: true,
        // 裁剪框的大小信息
        outputType: 'png',
        // 裁剪生成图片的格式
        canMove: false,
        // 上传图片是否可以移动
        canScale: false,
        // 图片是否允许滚轮缩放
        autoCrop: true,
        // 是否默认生成截图框
        // autoCropWidth: '20%', // 默认生成截图框宽度
        // autoCropHeight: '20%', // 默认生成截图框高度
        high: true,
        // 是否按照设备的dpr 输出等比例图片
        fixedBox: true,
        // 固定截图框大小 不允许改变
        fixed: true,
        // 是否开启截图框宽高固定比例
        fixedNumber: [4, 5],
        // 截图框的宽高比例
        full: false,
        // 是否输出原图比例的截图
        canMoveBox: true,
        // 截图框能否拖动
        original: false,
        // 上传图片按照原始比例渲染
        centerBox: true,
        // 截图框是否被限制在图片里面
        infoTrue: false,
        // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: 'contain' // 图片默认渲染方式
      }
    };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onConfirm() {
      //确定
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$refs.imageCropper.getCropBlob(data => {
        let file = new File([data], Date.now() + ".png", {
          type: "png"
        });
        let filesArr = [];
        filesArr.push(file);
        let MDOSSoption = {
          success: item => {
            console.log(item);
            this.$emit('success', item);
            this.loading = false;
          },
          error: (e, msg) => {
            mdToast({
              type: "text",
              message: msg,
              onClose: () => {}
            });
          }
        };
        OSSUpload(MDOSSoption, filesArr);
      });
    }
  }
};